<template>
    <div class="min-h-screen flex flex-col sm:justify-center items-center pt-6 sm:pt-0 bg-gray-100">

        <div class="w-full sm:max-w-md mt-6 px-6 py-4 bg-white shadow-md overflow-hidden sm:rounded-lg">
            
            
            <div class=" flex flex-col sm:justify-center items-center mb-9 mt-6 pt-6 sm:pt-0">
                <slot name="logo" />
            </div>
            
            <slot />
        </div>
    </div>
</template>
